import axios from "axios";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import Heading2 from "components/Heading/Heading2";
import { useAuth } from "context/authContext";
import { LocationType } from "data/types";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Swal from "sweetalert2";

export interface CommonLayoutProps {
  index: string;
  nextHref: string;
  backtHref: string;
  nextBtnText?: string;
  location?: LocationType;
}

const CommonLayout: FC<CommonLayoutProps> = ({
  children,
  nextHref,
  nextBtnText,
  backtHref,
  location,
}) => {
  const { authUser, loading, setLoading } = useAuth();
  const [data, setData] = useState([]);
  const [pastBookings, setPastBookings] = useState([]);
  const [futureBookings, setFutureBookings] = useState([]);
  const [user, setUser] = useState(null);

  const columns = [
    { name: "Title", selector: (row) => row.title },
    { name: "Date", selector: (row) => row.date },
    { name: "Price", selector: (row) => "$" + row.price },
  ];

  const history = useHistory();
  useEffect(() => {
    setLoading(true);

    if (authUser) {
      fetch("/api/loggedIn", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: authUser.email }),
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log("🚀 ~ .then ~ data:", data);
          setUser(data);
        });

      setLoading(false);
    } else {
      setData([]);
      setPastBookings([]);
      setFutureBookings([]);
      setUser(null);
      setLoading(false);

      history.push("/login");
    }
  }, [authUser]);
  useEffect(() => {
    setLoading(true);
    // console.log("🚀 ~ useEffect ~ user:", user);

    if (user) {
      fetch("/api/experiences/" + user._id)
        .then((response) => response.json())
        .then((data) => setData(data))
        .catch((error) => console.error(error));

      fetch("/api/bookings/" + user._id)
        .then((response) => response.json())
        .then((data) => {
          setFutureBookings(
            data
              .filter((booking) => moment(booking.date).diff(moment()) >= 0)
              .sort((a, b) => moment(a.date).diff(moment(b.date)))
          );

          setPastBookings(
            data
              .filter((booking) => moment(booking.date).diff(moment()) < 0)
              .sort((a, b) => moment(b.date).diff(moment(a.date)))
          );
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    }
  }, [user]);

  function deleteExperience(_id) {
    axios.delete("/api/experience/" + _id).then((res) => {
      setData(data.filter((item) => item._id !== _id));
    });
  }
  const stripeConnect = async () => {
    try {
      Swal.fire({
        title: " Stripe Connect",
        text: "You need to create stripe connect first to create experience once you create amount percentage will share with you . Do you want to create now?",
        showDenyButton: true,
        confirmButtonText: "Yes ",
        denyButtonText: `Not now`,
        customClass: {
          title: "text-neutral-800",

          confirmButton: "custom-confirm-button",
          denyButton: "custom-deny-button",
          cancelButton: "custom-cancel-button",
        },
        didOpen: () => {
          const confirmButton = Swal.getConfirmButton();
          const denyButton = Swal.getDenyButton();

          if (confirmButton) {
            confirmButton.classList.add(
              "bg-primary-500",
              "text-white",
              "rounded-lg"
            );
          }

          if (denyButton) {
            denyButton.classList.add("bg-red-500", "text-white", "rounded-lg");
          }
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const email = user.email;
          console.log("🚀 ~ stripeConnect ~ user:", user);

          const { data } = await axios.post("/api/stripe/connect", { email });
          const { url } = data;

          if (url) {
            window.location.href = url;
          } else {
            console.log("No URL returned from Stripe.");
            Swal.fire("No URL returned from Stripe.");
            setLoading(false);
          }
        } else if (result.isDenied) {
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      console.error("Error connecting to Stripe:", error);
    }
  };

  return (
    <>
      {loading && (
        <div className="fixed inset-0 bg-gray-100 bg-opacity-80 flex justify-center items-center z-50">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-solid border-gray-300"></div>
        </div>
      )}
      {authUser && (
        <div
          className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
          data-nc-id="PageAddListing1"
        >
          <div className="space-y-11">
            <Heading2
              heading="My Experiences"
              subHeading={
                <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
                  {data.length}{" "}
                  {data.length === 1 ? "experience" : "experiences"}
                </span>
              }
            />
            <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
              <div className="edit add-new">
                {user?.isStripeConnectedAccount ? (
                  <Link
                    to={{
                      pathname: "/listing-experiences-detail-edit",
                      state: {
                        editing: false,
                        _id: user._id,
                        firstName: user.firstName,
                        lastName: user.lastName,
                      },
                    }}
                  >
                    <ButtonPrimary>+</ButtonPrimary>
                  </Link>
                ) : (
                  <Link
                    to={{
                      state: {
                        editing: false,
                        _id: user?._id,
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                      },
                    }}
                  >
                    <ButtonPrimary onClick={stripeConnect}>+</ButtonPrimary>
                  </Link>
                )}

                {/* <Link
           to={{
             pathname: "/listing-experiences-detail-edit",
             state: {
               editing: false,
               _id: location.state._id,
               firstName: location.state.firstName,
               lastName: location.state.lastName,
             },
           }}
         >
           <ButtonPrimary>+</ButtonPrimary>
         </Link> */}
              </div>
              {data.map((stay) => {
                // console.log(stay);
                return (
                  <div
                    className="edit"
                    onClick={() => console.log({ id: stay._id, editing: true })}
                  >
                    <button
                      onClick={() => deleteExperience(stay._id)}
                      className="deleteButton"
                    >
                      x
                    </button>
                    <ExperiencesCard
                      onClick={() =>
                        history.push({
                          pathname: "/listing-experiences-detail-edit",
                          state: { _id: stay._id, editing: true },
                        })
                      }
                      key={stay._id}
                      editing={true}
                      data={stay}
                    />
                    {/* <ExperiencesCard key={stay._id} editing={true} data={stay} /> */}
                  </div>
                );
              })}
            </div>
            <Heading2
              heading="My Bookings"
              subHeading={
                <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
                  {pastBookings.length + futureBookings.length}{" "}
                  {pastBookings.length + futureBookings.length === 1
                    ? "booking"
                    : "bookings"}
                </span>
              }
            />
            <DataTable
              title="Future Bookings"
              columns={columns}
              data={futureBookings}
            />

            <DataTable
              title="Past Bookings"
              columns={columns}
              data={pastBookings}
            />
            {/* <div className="flex mt-16 justify-center items-center">
     <Pagination />
   </div> */}
            {/* <div>
       <span className="text-4xl font-semibold">{index}</span>{" "}
       <span className="text-lg text-neutral-500 dark:text-neutral-400">
         / 10
       </span>
     </div> */}

            {/* --------------------- */}
            {/* <div className="listingSection__wrap ">{children}</div> */}

            {/* --------------------- */}
            {/* <div className="flex justify-end space-x-5">
       <ButtonSecondary href={backtHref}>Go back</ButtonSecondary>
       <ButtonPrimary href={nextHref}>
         {nextBtnText || "Continue"}
       </ButtonPrimary>
     </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default CommonLayout;
