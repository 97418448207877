import React from "react";

const ZVCLogo = () => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 102 102"
    width="102"
    height="102"
  >
    <defs>
      <style>.cls-1</style>
    </defs>
    <path
      className="cls-1"
      d="M93.11,78.01c5.01-7.8,7.92-17.07,7.92-27.01C101.03,23.43,78.68,1,51.2,1S1.38,23.43,1.38,51c0,16.51,8.02,31.17,20.34,40.28.11.1.24.19.37.27,8.2,5.94,18.25,9.45,29.11,9.45,17.36,0,32.67-8.95,41.59-22.5.12-.15.23-.31.32-.49ZM6.12,51C6.12,26.06,26.35,5.76,51.2,5.76s45.08,20.29,45.08,45.24c0,8.35-2.27,16.18-6.22,22.9l-6.53-3v-28.92l-4.73-2.16v-15.09h-12.69v8.31l-4.61,3.17v7.8l-12.47,6.22v15.3c-.76.33-1.78.81-2.93,1.46v-5.56l-12.47-6.22v-7.8l-7.63-5.25-7.82,11.83v27.78c-7.49-8.08-12.07-18.9-12.07-30.78ZM24.07,87.1c-.38-.29-.76-.59-1.13-.89v-13.09h3.07c1.31,0,2.37-1.07,2.37-2.38s-1.06-2.38-2.37-2.38h-3.07v-12.93l4.36-6.6,1.59,1.09v12.99c0,1.31,1.06,2.38,2.37,2.38s2.37-1.07,2.37-2.38v-2.38l7.73,3.85v5.94c-2.64,2.3-5.09,5.4-6.16,9.45-.79,3.02-2.94,5.16-6.38,6.38-1.93.68-3.77.89-4.75.95ZM29.85,90.83c.13-.04.26-.08.4-.13,5.03-1.73,8.34-5.08,9.55-9.71,1.31-4.98,5.7-8.21,8.84-9.95-1.92,2.54-3.7,7.36,1.76,14.24,4.73,5.98,5.03,9.42,4.59,10.79-1.24.1-2.5.16-3.77.16-7.72,0-15-1.96-21.36-5.41ZM59.86,95.4c.12-2.86-1.04-7.11-5.76-13.07-3.04-3.84-3.4-6.9-1.07-9.1,0,0,.02-.02.02-.03.07-.06.12-.14.18-.21.04-.05.09-.1.12-.15.05-.07.08-.14.12-.21.04-.06.08-.13.1-.19.03-.07.05-.14.07-.21.03-.08.05-.15.07-.23.01-.06.02-.13.02-.19.01-.09.03-.18.03-.27,0-.01,0-.02,0-.04v-18.3l7.73-3.85v2.38c0,1.31,1.06,2.38,2.37,2.38s2.37-1.07,2.37-2.38v-12.99l4.61-3.17v-6.06h3.2v13.39l4.73,2.16v9.25h-3.92c-1.31,0-2.37,1.07-2.37,2.38s1.06,2.38,2.37,2.38h3.92v10.98h-10.61c-1.31,0-2.37,1.07-2.37,2.38s1.06,2.38,2.37,2.38h12.47l6.76,3.11c-6.58,8.89-16.33,15.28-27.55,17.48Z"
    />
  </svg>
);

export default ZVCLogo;
