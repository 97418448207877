"use client";

import { I_UseProvideAuth, useProvideAuth } from "config";
import { ReactNode, createContext, useContext } from "react";

// const authContext:I_UseProvideAuth = createContext({} as I_UseProvideAuth);
const authContext = createContext({} as I_UseProvideAuth);
// Provider component that wraps your app and makes auth object ..
// ... available to any child component that calls useAuth().

interface AuthProviderProps {
  children: ReactNode;
  // You can add other generic properties if needed
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.

export const useAuth = () => {
  return useContext(authContext);
};
