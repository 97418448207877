import { TokenResponse } from "@react-oauth/google";
import { useAuth } from "context/authContext";
import { FC, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";

export interface PageLoginProps {
  className?: string;
}

type TokenResponseWithoutError = Omit<
  TokenResponse,
  "error" | "error_description" | "error_uri"
>;

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const [user, setUser] = useState<
    Omit<TokenResponse, "error" | "error_description" | "error_uri">[]
  >([]);
  const { resetPassword, loading } = useAuth();

  const [email, setEmail] = useState("");

  const history = useHistory();

  return (
    <>
      {loading && (
        <div className="fixed inset-0 bg-gray-100 bg-opacity-80 flex justify-center items-center z-50">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-solid border-gray-300"></div>
        </div>
      )}

      <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
        <Helmet>
          <title>Forgot Password || Booking React ZVC </title>
        </Helmet>
        <div className="container mb-24 lg:mb-32">
          <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Forgot Password{" "}
          </h2>
          <div className="max-w-md mx-auto space-y-6">
            <form
              className="grid grid-cols-1 gap-6"
              onSubmit={(e) => {
                e.preventDefault();
                resetPassword(email);
              }}
              action="#"
              method="post"
            >
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  Email address
                </span>
                <Input
                  id="email"
                  required
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    e.target.setCustomValidity("");
                  }}
                  type="email"
                  placeholder="example@example.com"
                  className="mt-1"
                />
              </label>

              <ButtonPrimary type="submit">Continue</ButtonPrimary>
            </form>

            {/* ==== */}

            <span className="block text-center text-neutral-700 dark:text-neutral-300">
              <span
                className="link"
                onClick={() => {
                  history.push("/login");
                  const inputElement = document.getElementById(
                    "email"
                  ) as HTMLInputElement;
                  inputElement.setCustomValidity("");
                }}
              >
                Back to Sign in
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageLogin;
