import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  User,
  createUserWithEmailAndPassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  UserCredential,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "./firebase";
import Swal from "sweetalert2";

export interface I_UseProvideAuth {
  authUser: any | undefined;
  setAuthUser: React.Dispatch<React.SetStateAction<any | undefined>>;
  userLogin: (email: string, password: string) => Promise<string>;
  createUser: (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => Promise<string>;
  userSignOut: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  loading: boolean;
  loadingAuthUser: boolean;
  error: string;
  tokenId: () => Promise<string | undefined>;
  reauthenticatePassword: (
    email: string,
    password: string
  ) => Promise<UserCredential>;
  openIssue: boolean;
  setOpenIssue: React.Dispatch<React.SetStateAction<boolean>>;
  authUserProfile: any | undefined;
  setAuthUserProfile: React.Dispatch<React.SetStateAction<any | undefined>>;
  googleSignIn: () => Promise<void>;
  checkUserClaims: () => Promise<string>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useProvideAuth = (): I_UseProvideAuth => {
  const [authUser, setAuthUser] = useState<User | undefined>();
  const [authUserProfile, setAuthUserProfile] = useState<User | undefined>();
  const [loadingAuthUser, setLoadingAuthUser] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [openIssue, setOpenIssue] = useState(false);

  const fetchStart = () => {
    setLoading(true);
    setError("");
  };

  const fetchSuccess = () => {
    setLoading(false);
    setError("");
  };

  const fetchError = (error: string) => {
    setLoading(false);
    setError(error);
  };
  const userLogin = async (email: string, password: string) => {
    email = email.toLowerCase(); // Ensure email is lowercase

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      const user = userCredential.user;

      const idTokenResult = await user.getIdTokenResult();
      if (
        idTokenResult.claims["role"] === "admin" ||
        idTokenResult.claims["role"] === "User"
      ) {
        setAuthUser(user);
        fetchSuccess();
        return idTokenResult.claims["role"];
      } else if (idTokenResult.claims["role"] === "Unknown") {
        Swal.fire({
          title: "Unauthorized",
          text: "Your account is not approved yet. Please contact the admin.",
          icon: "error",
        });
        userSignOut();
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Incorrect email or password. Please try again.",
        // icon: "error",
      });
      console.error("🚀 ~ userLogin ~ error:", error);
      fetchError(error.message); // Use error.message for better readability
    } finally {
      setLoading(false);
    }
  };
  const createUser = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) => {
    email = email.toLowerCase(); // Correcting the email to lowercase
    try {
      fetchStart();
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      await updateProfile(user, {
        displayName: `${firstName} ${lastName}`, // Set the display name here
      });

      Swal.fire({
        title: "Sign up successful!",
        text: "Waiting for admin approval!",
        icon: "success",
      });

      await userSignOut(); // Sign the user out if needed
      return "User created successfully"; // Return the success message or any value you need
    } catch (error) {
      fetchError(error as string);
      return null; // Return null or an error message if something goes wrong
    } finally {
      setLoading(false); // Ensure loading state is reset in both success and error scenarios
    }
  };

  const userSignOut = async () => {
    signOut(auth)
      .then(() => {
        setAuthUser(undefined);
      })
      .catch((error) => {
        console.log("🚀 ~ userSignOut ~ error:", error);
      })
      .finally(() => setLoading(false));
  };
  const tokenId = async () => {
    return await authUser?.getIdToken();
  };

  const resetPassword = async (email: string) => {
    try {
      fetchStart();
      sendPasswordResetEmail(auth, email).then(async (response) => {
        fetchSuccess();
        Swal.fire({
          title: "Password reset email sent",
          icon: "success",
        });
      });
    } catch (error) {
      fetchError(error as string);
    }
  };

  const googleSignIn = async () => {
    setLoading(true);
    try {
      fetchStart();
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      setAuthUser(result.user);
      fetchSuccess();
    } catch (error) {
      console.error("🚀 ~ googleSignIn ~ error:", error);
      fetchError(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(undefined);
        setAuthUserProfile(undefined);
      }
      setLoadingAuthUser(false);
    });

    return () => unsubscribe();
  }, [authUser]);
  useEffect(() => {
    if (authUser) {
    } else {
      setAuthUserProfile(undefined);
    }
  }, [authUser]);
  const reauthenticatePassword = async (email: string, password: string) => {
    try {
      const user = auth.currentUser;
      if (user) {
        const resp = await reauthenticateWithCredential(
          user,
          EmailAuthProvider.credential(email, password)
        );
        return Promise.resolve(resp);
      } else {
        return Promise.reject("No authenticated user found.");
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };
  const checkUserClaims = async (): Promise<string> => {
    if (!authUser) {
      throw new Error("No authenticated user found.");
    }

    try {
      const idTokenResult = await authUser.getIdTokenResult();

      // Ensure 'role' exists and is a string
      const role = idTokenResult.claims.role as string | undefined;

      if (role) {
        return role;
      } else {
        throw new Error("Role claim is not defined.");
      }
    } catch (error) {
      console.error("🚀 ~ checkUserClaims ~ error:", error);
      throw new Error("Failed to retrieve user claims.");
    }
  };

  return {
    loadingAuthUser,
    loading,
    authUser,
    error,
    resetPassword,
    setAuthUser,
    userLogin,
    userSignOut,
    tokenId,
    createUser,
    reauthenticatePassword,
    openIssue,
    setOpenIssue,
    authUserProfile,
    setAuthUserProfile,
    googleSignIn,
    checkUserClaims,
    setLoading,
  };
};
