import axios from "axios"; // Make sure to install axios
import { useAuth } from "context/authContext";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const { checkUserClaims, authUser, loading, setLoading } = useAuth();
  const history = useHistory();

  // Fetch users from the backend
  const fetchUsers = async () => {
    try {
      const role = await checkUserClaims();

      if (role === "User") {
        history.push("/");
        history.go(0);
      } else if (role === "admin") {
        const response = await axios.get("/api/users");
        // console.log("🚀 ~ fetchUsers ~ response:", response);
        setUsers(response.data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // Handle role toggle
  const handleToggle = async (email: string, currentRole: string) => {
    setLoading(true);
    const newRole = currentRole === "Unknown" ? "User" : "Unknown";
    try {
      await axios.put(`/api/users/${email}/role`, { role: newRole }); // Update role on the server
      // Update local state
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.email === email ? { ...user, role: newRole } : user
        )
      );
    } catch (error) {
      console.error("Error updating user role:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authUser) {
      fetchUsers();
    } else if (!authUser) {
      setUsers([]);
    }
  }, [authUser]);

  return (
    <>
      {loading && (
        <div className="fixed inset-0 bg-gray-100 bg-opacity-80 flex justify-center items-center z-50">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500 border-solid border-gray-300"></div>
        </div>
      )}

      <div className="min-h-screen flex flex-col  items-center bg-gray-100 py-12">
        {authUser ? (
          <div className="relative overflow-x-auto w-full max-w-6xl justify-center">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Role
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Edit
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr
                    key={user.email}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {user.email}
                    </th>
                    <td className="px-6 py-4">{user.role}</td>
                    <td className="px-6 py-4">
                      <button
                        disabled={user.role === "User"}
                        onClick={() => handleToggle(user.email, user.role)}
                        className={`inline-flex items-center px-3 py-1 text-sm font-medium rounded-md ${
                          user.role === "User"
                            ? "bg-green-500 text-white"
                            : "bg-red-500 text-white"
                        }`}
                      >
                        {user.role}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : !authUser && loading ? (
          <div className=" flex flex-col items-center justify-center w-full h-full p-8 bg-white rounded-lg shadow-md text-center">
            <h1 className="text-2xl font-semibold text-gray-700 mb-4">
              Access Denied
            </h1>
            <p className="text-gray-500 mb-4">
              You need to log in as an admin.
            </p>
            <button
              onClick={() => history.push("/login")}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-500 transition"
            >
              Go to Login
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default AdminPage;
